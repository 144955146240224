import styled from "styled-components";
import { Dialog } from "@mui/material";

const DeleteDataStyles = {
  Dialog: styled(Dialog)`
    & .MuiPaper-root {
      height: 40%;
      max-height: 296px;
      width: 60%;
      max-width: 750px;
      border-radius: 30px;
      overflow: hidden;
      @media ${(props) => props.theme.breakpoints.md} {
        height: 100%;
        width: 100%;
        margin: 0;
        max-width: unset;
        max-height: unset;
        border-radius: 0;
      }
    }
  `,
  Container: styled.div`
    height: 100%;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  ButtonArea: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  `,
  DeleteButton: styled.button`
    background-color: #c13a3aff;
    height: 48px;
    width: 152px;
    border: 1px solid #c13a3aff;
    border-radius: 25px;
    color: #ffffff;
    font-size: 17px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    @media (max-width: 525px) {
      margin-top: 20px;
    }
  `,
  CancelButton: styled.button`
    background-color: transparent;
    height: 48px;
    width: 152px;
    border: 1px solid #171111;
    border-radius: 25px;
    color: #171111;
    font-size: 17px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    @media (max-width: 525px) {
      margin-top: 20px;
    }
  `,
  Title: styled.div`
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 24px;
  `,
  Subtitle: styled.div`
    font-size: 18px;
  `,
  Iframe: styled.iframe`
    height: 3300px;
    width: 100%;
    @media ${(props) => props.theme.breakpoints.sm} {
      height: 3500px;
    }
  `,
};

export default DeleteDataStyles;
