import React, { useEffect, useState } from "react";
import DeleteDataStyles from "./DeleteDataStyles";
import FooterSection from "../Footer";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClient } from "../../context/ClientContext";
import { removeChatbotSources } from "../../actions";
import { LoadingAnimation } from "../../pages/SplashPage/SplashPage";

const DeleteData = () => {
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.sm);
  const { setDeleteModalOpen, currentChatbot, currentUser, setSources } = useClient();
  const [deleting, setDeleting] = useState(false);
  const formEl: HTMLElement | null = document.getElementById("my-reform");
  const formElHtml: string | undefined = formEl?.innerHTML;

  const deleteSources = () => {
    console.log({ userID: currentUser.userID, chatbotID: currentChatbot.chatbotID });
    setDeleting(true);
    removeChatbotSources({ userID: currentUser.userID, chatbotID: currentChatbot.chatbotID }).then((res) => {
      console.log(res);
      if (res.result === "SUCCESS") {
        setSources([]);
      }
      setDeleteModalOpen(false);
    });
  };
  return (
    <DeleteDataStyles.Dialog open={true}>
      {/* <FontAwesomeIcon
        onClick={() => setDeleteModalOpen(false)}
        icon={faXmark}
        size="xl"
        style={{
          position: "absolute",
          top: "15px",
          right: "20px",
          zIndex: "3",
          cursor: "pointer",
        }}
      /> */}
      <DeleteDataStyles.Container>
        <div>
          <DeleteDataStyles.Title>Are you sure you want to start over?</DeleteDataStyles.Title>
          <DeleteDataStyles.Subtitle>
            This action will irreversibly delete all the data your AI chatbot is trained on, rendering it unable to answer your questions until a new
            set of training data is uploaded. This action cannot be undone.
          </DeleteDataStyles.Subtitle>
        </div>
        {!deleting && (
          <DeleteDataStyles.ButtonArea>
            <DeleteDataStyles.CancelButton onClick={() => setDeleteModalOpen(false)}>Cancel</DeleteDataStyles.CancelButton>
            <DeleteDataStyles.DeleteButton onClick={deleteSources}>Delete all data</DeleteDataStyles.DeleteButton>
          </DeleteDataStyles.ButtonArea>
        )}
        {deleting && (
          <DeleteDataStyles.ButtonArea>
            {" "}
            <LoadingAnimation />
          </DeleteDataStyles.ButtonArea>
        )}
      </DeleteDataStyles.Container>
    </DeleteDataStyles.Dialog>
  );
};

export default DeleteData;
