import axios from "axios";
import { MessageType } from "../types/AuthTypes";

const API_SOURCE = process.env.REACT_APP_API_SOURCE;
const API_KEY = process.env.REACT_APP_API_KEY;
const IB_MEDIA_URL = process.env.REACT_APP_INFOBOX_MEDIA;
const IB_MEDIA_AUTH = process.env.REACT_APP_INFOBOX_MEDIA_AUTH;

declare var xurealAPI: any;
declare var xurealAI: any;
declare var xurealAIButton: any;

export const initXurealAI = () => {
  return new Promise((resolve, reject) => {
    try {
      xurealAI.initEnvURLS({
        aiURL: process.env.REACT_APP_API_SOURCE_AI,
        appURL: process.env.REACT_APP_API_SOURCE_PLATFORM,
        mediaURL: process.env.REACT_APP_API_SOURCE_MEDIA,
      });
      resolve("SUCCESS");
    } catch (e) {
      console.log(e);
      reject("XUREAL AI ERROR");
    }
  });
};

export const initApiClient = () => {
  return new Promise((resolve, reject) => {
    try {
      xurealAPI.init(API_SOURCE, API_KEY);
      resolve("SUCCESS");
    } catch (e) {
      console.error(e);
      reject("ERROR");
    }
  });
};

export const initAI = (generatedClient: string) => {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        xurealAIButton.init(
          "https://ai-services.dev.xureal.com/ai-chatbox/?client=" + generatedClient,
          {
            right: "0px",
            bottom: "0px",
          }
          // { backgroundColor: "#191919", color: "#faa41a" }
        );
      }, 1e3);
    } catch (e) {
      console.error(e);
      reject("ERROR");
    }
  });
};

export const initJWT = (jwt: string) => xurealAI.initJWT(jwt);

export const verifyEncoding = (obj: { encoding: string }) => xurealAI.verifyEncoding(obj);

export const postMediaContent = (uploadData: { file: File; tag?: string }) => xurealAI.postMediaContent(uploadData);

export const getAllMessages = (obj: { xurealID: string }) => xurealAI.getAllMessages(obj);

export const decryptPasswordReset = (dataObj: { encoding: string }) => xurealAI.verifyPwEncoding(dataObj);

export const updatePassword = (dataObj: { password: string; token: string }) => xurealAI.updatePassword(dataObj);

export const sendPlayerMessage = (obj: { xurealID: string; message: string; client_name: string }) => xurealAI.sendPlayerMessage(obj);

export const sendMessage = (obj: { messages: MessageType[]; client_name: string }) => xurealAI.sendMessage(obj);

export const analyzePage = (obj: { url: string }) => xurealAI.analyzePage(obj);

export const createChatBot = (dataObj: {
  sources: string[];
  assistant_name: string;
  widget_logo: string;
  widget_avatar: string;
  widget_color: string;
}) => xurealAI.createChatbot(dataObj);

export const customizeChatbot = (dataObj: {
  assistant_name?: string;
  widget_avatar?: string;
  widget_logo?: string;
  widget_color?: string;
  chatbotID: string;
}) => xurealAI.customizeChatbot(dataObj);

export const loginUser = (dataObj: { email: string; password: string }) => xurealAI.loginUser(dataObj);

export const signupUser = (dataObj: { email: string; password: string; firstname: string; lastname: string }) => xurealAI.signupUser(dataObj);

export const getUserData = () => xurealAI.getUser();

export const onboardUser = () => xurealAI.onboardUser();

export const scrapePage = (obj: { urls: string[]; companyName: string; tempID: string }) => xurealAI.scrapePage(obj);

export const storeClientData = (obj: {
  system_context: string;
  client_name: string;
  content: { header: string; section: string; title: string }[];
  assistant_name: string;
}) => xurealAI.storeClientData(obj);

export const getUserChatbots = () => xurealAI.getUserChatbots();

export const getSourcesByChatbot = (obj: { chatbotID: string }) => xurealAI.getSourcesByChatbot(obj);

export const getSourceByID = (obj: { sourceID: string; chatbotID: string }) => xurealAI.getSourceByID(obj);

export const createSources = async (dataObj: {
  files: {
    name: string;
    sourceType: string;
    sourceData: { [key: string]: any };
  }[];
  chatbotID?: string;
}) => xurealAI.createSources(dataObj);

export const createFileUploadSource = async (dataObj: { file: string; chatbotID?: string; originalName: string }) =>
  xurealAI.createFileUploadSource(dataObj);

export const fileUpload = async (dataObj: { file: File; tag?: string; client: string }) => {
  try {
    const response = await axios.post(`${IB_MEDIA_URL}`, dataObj, {
      headers: { authtoken: IB_MEDIA_AUTH },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const removeChatbotSource = async (dataObj: { chatbotID: string; sourceID: string }) => xurealAI.removeChatbotSource(dataObj);

export const removeChatbotSources = async (dataObj: { chatbotID: string; userID: string }) => xurealAI.removeChatbotSources(dataObj);

export const toggleChatbotSource = async (dataObj: { chatbotID: string; sourceID: string }) => xurealAI.toggleChatbotSource(dataObj);

export const resetPassword = (dataObj: { email: string }): Promise<void> => xurealAI.resetPassword(dataObj);

export const authorizeGoogleDrive = async (jwt: string) => xurealAI.authorizeGoogleDrive(jwt);

export const getGoogleAccess = (dataObj: { accessToken: string }) => xurealAI.getGoogleAccess(dataObj);

export const checkUser = async () => xurealAI.checkUser();

export const getUserGoogleDrive = async () => {
  let response = await axios.get(process.env.REACT_APP_GOOGLE_DRIVE_ACCESS as string, {
    headers: {
      jwt: "Bearer " + localStorage.getItem("JWT_TOKEN"),
    },
  });
  console.log(response.data);
  return response.data;
};
// local calls
// export const getAllMessages = async (obj: { xurealID: string }) => {
//   let response = await axios.get(
//     "http://localhost:5014/xureal-ai-api/chat/xurealID/" + obj.xurealID
//   );
//   return response.data;
// };

// export const sendPlayerMessage = async (obj: {
//   xurealID: string;
//   message: string;
//   client_name: string;
// }) => {
//   let response = await axios.post(
//     "http://localhost:5014/xureal-ai-api/chat/send_player_message",
//     obj
//   );
//   return response.data;
// };

// export const sendMessage = async (obj: {
//   messages: MessageType[];
//   client_name: string;
// }) => {
//   let response = await axios.post(
//     "http://localhost:5014/xureal-ai-api/chat/send_message",
//     obj
//   );
//   return response.data;
// };

// export const analyzePage = async (obj: { url: string }) => {
//   let response = await axios.post(
//     "http://localhost:5014/xureal-ai-api/scraper/analyze",
//     obj
//   );
//   return response.data;
// };

// export const scrapePage = async (obj: {
//   urls: string[];
//   companyName: string;
//   tempID: string;
// }) => {
//   let response = await axios.post(
//     "http://localhost:5014/xureal-ai-api/scraper/scrape",
//     obj
//   );
//   return response.data;
// };

// export const storeClientData = async (obj: {
//   system_context: string;
//   client_name: string;
//   content: { header: string; section: string; title: string }[];
//   assistant_name: string;
// }) => {
//   let response = await axios.post(
//     "http://localhost:5014/xureal-ai-api/context_data",
//     obj
//   );
//   return response.data;
// };
